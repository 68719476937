import { useMatch, Routes, BrowserRouter, Route,useRoutes,Navigate, Outlet, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import URLConfig from "./URLConfig.js";
import DeltaHomeContext from "./Context/DeltaHomeContext.js";
import DeltaHomeStates from "./Context/DeltaHomeStates.js";
import Home from "./Components/Home.js";
import DocumentSearchResult from "./Components/DcoumentSearchResult.js";
import SearchFormRedesign from"./Components/SearchFormRedesign.js"
import axios from "axios";

const DeltaRoutes = (props) => {
  
  const envData = URLConfig.get_Env(); // get the application environment (UAT/PROD)
  const location = useLocation()

  const deltaHomeStates = DeltaHomeStates(props)
//     console.log("deltaHomeStates",deltaHomeStates)

const DeltaAuthRoute = ({ to }) => {
  //added to bypass auth and to load radar external link by Aathira
  if(to == "Radar")
  {
    axios.get(URLConfig.URL_GetRadarURL).then((res) =>
      window.location.href = res?.data
    )
  }
  
};

  const routesArray = [
    {
      exact: true,
      path: "/",
      element:<Home location={location} 
                    imageMapperLoaded={deltaHomeStates.imageMapperLoaded}
              />,

      children:[
        {
          path:"",
          element:<SearchFormRedesign
          searchTerm={deltaHomeStates.searchTerm}
          onSubmit={deltaHomeStates.onSearchFormSubmit}
          onCustomerSubmit={deltaHomeStates.onCustomerSearchFormSubmit}
          setActiveFilterAndValue={deltaHomeStates.setActiveFilter}
          resetRefinedSearch={deltaHomeStates.resetRefinedSearch}
          activeFilter={deltaHomeStates.activeFilter}
          CustomerIDValue={deltaHomeStates.fineSearchValue}
          showHideDivProjects={deltaHomeStates.showHideDivProjects}
          showFeedback={deltaHomeStates.showFeedback}
          toggleDocDepoFun={deltaHomeStates.toggleDocDepoFun}
          showDocDepo = {deltaHomeStates.showDocDepo}
          triggeredRefineSearch={deltaHomeStates.triggeredRefineSearch}
          imageMapperLoaded={deltaHomeStates.imageMapperLoaded}
          setImageMapperLoaded={deltaHomeStates.setImageMapperLoaded}
          />
        },
        
        {
          path:"search",
          element:<DocumentSearchResult/>
        },
        {
          path:"search1",
          element:<DocumentSearchResult/>
        },
        

      ]
    },
    {
      path: "/radar",
      element: <DeltaAuthRoute to={"Radar"} />,
    },

  ];

  const routesElement = useRoutes(routesArray);
  return <><DeltaHomeContext.Provider value={deltaHomeStates}>{routesElement}</DeltaHomeContext.Provider></>;

};

export default DeltaRoutes;
